import { Ref } from 'vue'

import { api } from '@/main'
import useGetModel from './generic'
import { AiUnit } from '@/api/units'

type GetArgs = Parameters<typeof api.units.unitsGetUnit>
type UpdateArgs = Parameters<typeof api.units.unitsUpdateUnit>
const get = (...args: GetArgs) => api.units.unitsGetUnit(...args)
const update = (...args: UpdateArgs) => api.units.unitsUpdateUnit(...args)

export default (): {
    readonly item: Ref<AiUnit>;
    fetchItem: (...args: GetArgs) => void;
    // eslint-disable-next-line
    fetchByName: (name: string, options?:any) => void;
    readonly error: Ref<boolean>;
    readonly loading: Ref<boolean>;
    clearError: () => void;
} => useGetModel(get, update)
