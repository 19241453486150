import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// import HomeView from '@/views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/Analytics.vue'),
  },
  {
    path: '/kpis',
    name: 'KPIs',
    component: () => import('@/views/KPIs.vue'),
  },
  {
    path: '/spotlights',
    name: 'Spotlights',
    component: () => import('@/views/Spotlights.vue'),
  },
  {
    path: '/kpis/breakdown/date/:date',
    name: 'KPIBreakdown',
    component: () => import('@/views/KPIBreakdown.vue'),
    props: true,
  },
  {
    path: '/kpis/breakdown/spotlights/:spotlightId',
    name: 'VideoBreakdown',
    component: () => import('@/views/VideoBreakdown.vue'),
    props: true,
  },
  {
    path: '/procedure-library',
    name: 'Procedure Library',
    component: () => import('@/views/ProcedureLibrary'),
  },
  {
    path: '/procedure-library/:procedureId',
    name: 'Procedure Details',
    component: () => import('@/views/ProcedureDetails'),
  },
  {
    path: '/procedure-library/:procedureId/audit/:devResolutionId',
    name: 'Procedure Audit',
    component: () => import('@/views/Audit'),
  },
  {
    path: '/procedure-library/:procedureId/:macroStep/details',
    name: 'Procedure Macrostep Details',
    component: () => import('@/views/MacroStepDetails'),
  },
  {
    path: '/procedure-library/:procedureId/:macroStep/sli',
    name: 'Macrostep SLI',
    component: () => import('@/views/SLI'),
  },
  {
    path: '/procedure-library/:procedureId/:macroStep/annotation-results',
    name: 'Annotation Results',
    component: () => import('@/views/AnnotationResults'),
  },
  // {
  //   path: '/benchmarks',
  //   name: 'Benchmarks',
  //   component: () => import('@/views/Benchmarks.vue')
  // },
  // {
  //   path: '/benchmarks/presets/:presetId/kpiresults/:benchmarkId',
  //   name: 'BenchmarkInfo',
  //   component: () => import('@/views/BenchmarkInfo.vue'),
  //   props: true
  // },
  // {
  //   path: '/benchmarks/:benchmarkId/video/:videoId',
  //   name: 'Video Breakdown',
  //   component: () => import('@/views/BenchmarkBreakdown.vue'),
  //   props: true
  // },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () => import('@/views/Sessions.vue'),
  },
  {
    path: '/units',
    name: 'Units',
    component: () => import('@/views/Units'),
  },
  {
    path: '/unit/sessions/:sessionId/units/:unitId',
    name: 'Unit',
    component: () => import('@/views/Unit'),
  },
  {
    path: '/sessions/:sessionId/video',
    name: 'Session Video',
    component: () => import('@/views/SessionVideo.vue'),
    props: true, // <-- this will put the path params in props
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
