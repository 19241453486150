/* eslint-disable no-return-assign */
import { ref, reactive } from 'vue'
import type { Ref } from 'vue'
import { api } from '@/main'
import { AiInference, AiStepDetection, AiTrack } from '@/api/inferences'
import { AiBbox } from '@/api/steps'
import { AiProcedure, AiProcedureStep } from '@/api/procedures'
// import { useUsersStore } from '@/stores/users/users'

// probably doesn't make sense to add this here, but it also doesn't make sense
// to add it as part of the arguments or the library data structure
// const usersStore = useUsersStore()

export interface TimestampedInference {
  timestamp: number,
  frames: {
    step: string,
    bbox: AiBbox | {
      x: number,
      y: number,
      width: number,
      height: number,
    },
  }[],
}

interface FrameMetadata {
  expectedDisplayTime: number,
  mediaTime: number,
  presentationTime: number,
  presentedFrames: number,
  processingDuration: number,
  width: number,
  height: number,
}

export interface FrameSelection {
  unitVideoSec: number,
  sessionVideoPts: number,
}

interface AuditLibrary {
  isVideoAttached: Ref<boolean>
  isVideoPlaying: Ref<boolean>
  isVideoPaused: Ref<boolean>

  currentVideoSec: Ref<number>
  totalVideoSec: Ref<number>

  inferenceLoadingProgress: Ref<number>
  areInferencesLoading: Ref<boolean>
  areInferencesAvailable: Ref<boolean>
  inference?: Ref<TimestampedInference | undefined>

  videoProgress: Ref<number>
  isGivenFrameRequestedForAnnotation: Ref<boolean>

  init: (video: HTMLVideoElement, sessionId: string, unitId: string, procedure: AiProcedure) => void,

  previousFrame: () => void
  previousSecond: () => void
  nextFrame: () => void
  nextSecond: () => void

  play: () => void
  pause: () => void

  frameSelections: Ref<FrameSelection[]>,
  goToFrame: (timestamp: number) => void,

  requestAnnotationForFrame: () => void
  removeAnnotationRequest: () => void
}

export const useAuditLibrary = (): AuditLibrary => {
  const isVideoAttached = ref(false)
  const isVideoPlaying = ref(false)
  const isVideoPaused = ref(true)

  const currentVideoSec = ref(0)
  const totalVideoSec = ref(0)

  const videoDimensions = reactive({ width: 0, height: 0 })

  const areInferencesLoading = ref(true)
  const areInferencesAvailable = ref(false)
  const allInferences = ref<TimestampedInference[]>([])
  const inference = ref<TimestampedInference>()

  const avgFrameThreshold = ref(0)

  const procedure = ref({} as AiProcedure)

  const videoNode = ref()
  const videoProgress = ref(0)
  const mediaTime = ref(0)

  const frameSelections: Ref<FrameSelection[]> = ref([])

  const isGivenFrameRequestedForAnnotation = ref(false)

  const onVideoFrameCallback = (_now: number, metadata: FrameMetadata) => {
    mediaTime.value = metadata.mediaTime
    videoNode.value.requestVideoFrameCallback(onVideoFrameCallback)

    pickInferencesToShow(metadata)
    countAndSetVideoProgress(metadata)
    checkIfCurrentFrameRequestedAnnotation()
  }

  const init = (video: HTMLVideoElement, sessionId: string, unitId: string, procedureItem: AiProcedure): void => {
    videoNode.value = video

    videoNode.value.addEventListener('loadedmetadata', () => {
      isVideoAttached.value = true

      currentVideoSec.value = 0
      totalVideoSec.value = Math.round(videoNode.value.duration)

      videoDimensions.width = videoNode.value.videoWidth
      videoDimensions.height = videoNode.value.videoHeight
    })

    videoNode.value.addEventListener('timeupdate', () => {
      currentVideoSec.value = Math.floor(videoNode.value.currentTime)
    })

    videoNode.value.requestVideoFrameCallback(onVideoFrameCallback)

    procedure.value = procedureItem
    fetchInferences(sessionId, unitId)
  }

  const pickInferencesToShow = (metadata: FrameMetadata) => {
    let filteredInference
    for (const inference of allInferences.value) {
      const { timestamp } = inference
      const { mediaTime } = metadata

      const isTimestampMatch = timestamp === mediaTime
      const isTimestampInThreshold = timestamp >= mediaTime && timestamp <= mediaTime + avgFrameThreshold.value
      const shouldInferenceBeDisplayed = isTimestampInThreshold || isTimestampMatch

      if (shouldInferenceBeDisplayed) {
        const { frames } = inference
        const modifiedFrames = frames.map(frame => {
          const { bbox } = frame

          const box = bbox as AiBbox
          const { width, height } = metadata
          return {
            ...frame,
            bbox: {
              x: (box.xMin as number) * 100 / width,
              y: (box.yMin as number) * 100 / height,
              width: (box.xMax as number) * 100 / width - (box.xMin as number) * 100 / width,
              height: (box.yMax as number) * 100 / height - (box.yMin as number) * 100 / height,
            },
          }
        })

        filteredInference = {
          ...inference,
          frames: modifiedFrames,
        }

        break
      }
    }

    if (filteredInference) {
      inference.value = filteredInference
    } else {
      inference.value = undefined
    }
  }

  // How fetch inferences works:
  // 1. Fetch Unit, get startPts and endPts values from it, and get videoSegments array, taking first element from it
  // 2. From videoSegments array, get videoId, using which fetch Video
  // 3. From Video, get startPts
  // 4. Get Inferences using numbered unit startPts and endPts values.

  const inferenceLoadingProgress = ref(0)

  const fetchInferences = async (sessionId: string, unitId: string) => {
    const { data: unit } = await api.units.unitsGetUnit({
      unit: unitId,
      session: sessionId,
    })

    const {
      startPts: unitStartPts,
      endPts: unitEndPts,
    } = unit

    const numberedStartPts = Number(unitStartPts)
    const numberedEndPts = Number(unitEndPts)

    const { videoSegments } = unit
    const areVideoSegmentsMissing = !(videoSegments as string[]).length
    if (areVideoSegmentsMissing) {
      areInferencesLoading.value = false
      areInferencesAvailable.value = false
      return
    }

    const [segment] = videoSegments as string[]
    const [, , , videoId] = segment.split('/')
    const { data: video } = await api.videos.videosGetVideo({
      video: videoId,
      session: sessionId,
    })

    const { startPts } = video

    const { data: { inferences } } = await (async () => {
      const pageSize = 1000
      let buffer: AiInference[] = []
      const data = { inferences: [] as AiInference[], pageToken: '1' }
      do {
        buffer = await api.inferences.inferencesListInferences({
          session: sessionId,
          readMask: 'pts,stepEvents,stepDetections,tracks,operatorGuidance',
          pageSize: pageSize,
          pageToken: data.pageToken,
          filter: JSON.stringify({
            $and: [
              { pts: { $gte: numberedStartPts } },
              { pts: { $lte: numberedEndPts } },
              { tracks: { $exists: true } },
              /*, FIXME: remove comment whenever manufacturer filter starts working again
              { manufacturer: { $in: usersStore.userGroup } }, */
            ],
          }),
        }).then(data => data.data.inferences) || []
        if (buffer.length < pageSize) {
          inferenceLoadingProgress.value = 1
        } else {
          const pts = +(buffer[buffer.length - 1]?.pts || 0)
          inferenceLoadingProgress.value = pts / numberedEndPts
        }
        data.inferences.push(...buffer)
        data.pageToken = `${+data.pageToken + 1}`
      } while (buffer.length === pageSize)
      return { data }
    })()

    const VIDEO_MULTIPLIER = Math.pow(10, 9)
    console.log('inferences', inferences)

    const procedureSteps = procedure.value.steps
    const inferencesObjects = (inferences as AiInference[]).map((inference) => {
      const { pts, tracks, operatorGuidance, stepDetections } = inference

      const frames = []
      for (const track of tracks as AiTrack[]) {
        const { label, object, bbox } = track

        const trackBbox = bbox as AiBbox

        const numberRegex = /\d|\d+/g
        const objectNumber = (object as string).match(numberRegex)?.[0]
        const objectName = `${label}${objectNumber}`

        const isTrackForMicroStep = (procedureSteps as AiProcedureStep[]).find(step => step.shortName as string === objectName)
        const isTrackForMacroStep = (procedureSteps as AiProcedureStep[]).find(step => step.stepIndicators?.includes(label as string))
        const isTrackForOperatorGuidance = (operatorGuidance?.bboxes as AiBbox[]).find(guidanceBbox =>
          guidanceBbox.xMin === trackBbox.xMin &&
          guidanceBbox.xMax === trackBbox.xMax &&
          guidanceBbox.yMin === trackBbox.yMin &&
          guidanceBbox.yMax === trackBbox.yMax,
        )
        const isTrackForStepDetection = (stepDetections as AiStepDetection[]).find(stepDetection =>
          (stepDetection.bbox as AiBbox).xMin === trackBbox.xMin &&
          (stepDetection.bbox as AiBbox).xMax === trackBbox.xMax &&
          (stepDetection.bbox as AiBbox).yMin === trackBbox.yMin &&
          (stepDetection.bbox as AiBbox).yMax === trackBbox.yMax,
        )

        if (isTrackForStepDetection) {
          const { step } = isTrackForStepDetection

          frames.push({
            bbox: trackBbox as AiBbox,
            step: step as string,
          })
        } else if (isTrackForOperatorGuidance) {
          const operatorGuidanceStep = operatorGuidance?.activeStep

          frames.push({
            bbox: trackBbox as AiBbox,
            step: operatorGuidanceStep as string,
          })
        } else if (isTrackForMicroStep) {
          const { numberMacro, numberMicro } = isTrackForMicroStep
          const { bbox } = track

          frames.push({
            bbox: bbox as AiBbox,
            step: `${numberMacro}-${numberMicro}`,
          })
        } else if (isTrackForMacroStep) {
          const { numberMacro, numberMicro } = isTrackForMacroStep
          const { bbox } = track

          frames.push({
            bbox: bbox as AiBbox,
            step: `${numberMacro}-${numberMicro}`,
          })
        }
      }

      const framePts = +(pts as string) - +(startPts as string)

      return {
        timestamp: framePts / VIDEO_MULTIPLIER,
        frames,
      }
    })

    console.log('inferencesObjects', inferencesObjects)
    // FIXME: we might have a potential floating frameThreshold because FPS is not constant, should dynamically calculate it
    const timestamps = inferencesObjects.map(inference => inference.timestamp)

    avgFrameThreshold.value = timestamps.reduce(([acc, last], x) => [acc + Math.abs(x - last), x], [0, timestamps[0]])[0] / timestamps.length
    console.log('avgFrameThreshold', avgFrameThreshold.value)
    allInferences.value = inferencesObjects

    areInferencesAvailable.value = true
    areInferencesLoading.value = false
  }

  const countAndSetVideoProgress = (meta: FrameMetadata) => {
    const currentTime = meta.mediaTime
    const duration = videoNode.value.duration

    videoProgress.value = (currentTime / duration) * 100
  }

  const checkIfCurrentFrameRequestedAnnotation = () => {
    isGivenFrameRequestedForAnnotation.value = false

    const isRequested = frameSelections.value.some((frame) => frame.unitVideoSec === mediaTime.value)
    if (isRequested) isGivenFrameRequestedForAnnotation.value = true
  }

  const previousFrame = () => videoNode.value.currentTime -= 0.2
  const previousSecond = () => videoNode.value.currentTime -= 1

  const nextFrame = () => videoNode.value.currentTime += 0.2
  const nextSecond = () => videoNode.value.currentTime += 1

  const play = () => {
    isVideoPlaying.value = true
    isVideoPaused.value = false
    videoNode.value.play()
  }

  const pause = () => {
    isVideoPlaying.value = false
    isVideoPaused.value = true
    videoNode.value.pause()
  }

  const requestAnnotationForFrame = () => {
    const isRequested = frameSelections.value.some((frame) => frame.unitVideoSec === mediaTime.value)

    if (!isRequested) {
      frameSelections.value.push({
        unitVideoSec: mediaTime.value,
        sessionVideoPts: 0,
      })

      isGivenFrameRequestedForAnnotation.value = true
    }
  }

  const goToFrame = (frameTimestamp: number) => videoNode.value.currentTime = frameTimestamp

  const removeAnnotationRequest = () => {
    frameSelections.value = frameSelections.value.filter(({ unitVideoSec }) => unitVideoSec !== mediaTime.value)

    isGivenFrameRequestedForAnnotation.value = false
  }

  return {
    isVideoAttached,
    isVideoPlaying,
    isVideoPaused,

    currentVideoSec,
    totalVideoSec,

    inferenceLoadingProgress,
    areInferencesLoading,
    areInferencesAvailable,

    videoProgress,
    isGivenFrameRequestedForAnnotation,

    init,

    inference,

    previousFrame,
    previousSecond,
    nextFrame,
    nextSecond,

    play,
    pause,

    frameSelections,
    goToFrame,

    requestAnnotationForFrame,
    removeAnnotationRequest,
  }
}
